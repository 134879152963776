import request from "@/lib/request";

export interface IMpWeixinSession {
    mp_user: string
    app_id: string
    name: string
    cookies: []
    cookie_expire: Date
}

export async function getSessions(): Promise<IMpWeixinSession[]> {
    const r = await request.get('business/mp/weixin/sessions');
    return r.data
}

export interface IMpWeixinLoginStatus{
    code:number
    message:string
    data:unknown
}

export interface IMpWeixinLoginResult{
    expire:string
}
